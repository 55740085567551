// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-blog-archive-js": () => import("./../../../src/templates/blog-archive.js" /* webpackChunkName: "component---src-templates-blog-archive-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-case-studies-archive-js": () => import("./../../../src/templates/case-studies-archive.js" /* webpackChunkName: "component---src-templates-case-studies-archive-js" */),
  "component---src-templates-case-study-js": () => import("./../../../src/templates/case-study.js" /* webpackChunkName: "component---src-templates-case-study-js" */),
  "component---src-templates-default-page-js": () => import("./../../../src/templates/default-page.js" /* webpackChunkName: "component---src-templates-default-page-js" */),
  "component---src-templates-home-page-js": () => import("./../../../src/templates/home-page.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-news-archive-js": () => import("./../../../src/templates/news-archive.js" /* webpackChunkName: "component---src-templates-news-archive-js" */),
  "component---src-templates-news-post-js": () => import("./../../../src/templates/news-post.js" /* webpackChunkName: "component---src-templates-news-post-js" */),
  "component---src-templates-not-found-page-js": () => import("./../../../src/templates/not-found-page.js" /* webpackChunkName: "component---src-templates-not-found-page-js" */),
  "component---src-templates-resources-archive-js": () => import("./../../../src/templates/resources-archive.js" /* webpackChunkName: "component---src-templates-resources-archive-js" */),
  "component---src-templates-sector-page-js": () => import("./../../../src/templates/sector-page.js" /* webpackChunkName: "component---src-templates-sector-page-js" */)
}

